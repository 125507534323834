<script>
import Mixin from './mixin'
export default {
  name: 'ConsoleMain',
  mixins: [Mixin],
  components: {
  },
  data () {
    return {}
  },
  computed: {
    menu () {
      return [
      ]
    }
  },
  destroyed () {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {},
  meta: {
    title: 'Console',
    name: 'Console'
  }
}
</script>

<template>
  <div class="console-main">
    <router-view />
  </div>
</template>

<style src="./assets/default.styl" lang="stylus"></style>

